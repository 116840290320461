<template>
	<div>
		<div class="box">
			<button v-if="dashboard" class="btn btn-secondary rounded-pill mb-2" @click="addContactFromTiers">{{ $t('action.create_contact_from_tiers') }} <font-awesome-icon :icon="['fal', 'plus']" class="ml-1" /></button>
			<CustomTable
				:id_table="tableId"
				ref="table"
				:items="filtredContacts"
				:busy.sync="table_busy"
				primaryKey="contact_id"
				:hide_if_empty="true"
				:rawColumns="rawColumnNames"
				:display_action_button="!horseFicheReadOnly"
				:checkboxes="!horseFicheReadOnly"
				:hrefsRoutes="config_table_hrefs"
				:columsAddBegin="columsAddBegin"
				:externSlotColumns="extern_slot_columns"
			>
				<template v-slot:[`custom-slot-cell(hubspot)`]="{ data }">
                    <a :href="'https://app.hubspot.com/contacts/8547080/contact/' + data.contact.contact_hubspotid"  target="_blank" v-if="data.contact.contact_hubspotid">
					    <font-awesome-icon :icon="['cf', 'hubspot']" />
                    </a>
				</template>
				<template v-slot:[`custom-slot-cell(contact_mail)`]="{ data }">
					<e-copybox classes="row" mail customtable :value="data.contact_mail"></e-copybox>                                             
				</template>
				<template v-slot:[`custom-slot-cell(phones_number)`]="{ data }">
					<span v-for="(phone_number, index) in data.phones_number.split('<br>')" :key="index">
						<e-copybox classes="row" phone customtable :value="phone_number"></e-copybox>                                             
					</span>
				</template>
			</CustomTable>
		</div>

		<ContactForm />
	</div>
</template>


<script type="text/javascript">
	import Vue from 'vue'
	import Countries from '@/assets/lang/countries/countries'
	import Navigation from "@/mixins/Navigation.js";
	import Tiers from "@/mixins/Tiers.js"
	import Horse from "@/mixins/Horse.js"

	export default {
		name: "Contactttttt",
		mixins: [Navigation, Tiers, Horse],
		data () {
			return {
				columsAddBegin: [],
				contacts: null,
				table_busy: true,
				config_table_hrefs: {
					'contact_firstname': {
						routeUniqueName: 'contactCoordonnees',
						routeName: 'contactCoordonnees',
						params: {
							contact_id: 'contact_id'
						}
					},
					'contact_lastname': {
						routeUniqueName: 'contactCoordonnees',
						routeName: 'contactCoordonnees',
						params: {
							contact_id: 'contact_id'
						}
					}
				},
				extern_slot_columns: [
					'hubspot',
					'phones_number',
					'contact_mail'
				],
				rawColumnNames: ['phones_number', "contact_poste"]
			}
		},
		props: ['tiers_id', 'horse_id', 'dashboard'],
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				if(this.hasHubspotAccess) {
					this.columsAddBegin.push('hubspot')
				}

				this.loadContacts()
			},
			async loadContacts() {
				if(this.tiers_id) {
					await this.loadContactsTiers()
				} else if(this.horse_id) {
					await this.loadContactsHorse()
				}
				this.$refs.table.resetCachedItems()
			},
			async loadContactsTiers() {
				this.table_busy = true
				this.contacts = await this.loadTiersContacts(this.tiers_id)
				this.table_busy = false
			},
			async loadContactsHorse() {
				this.table_busy = true
				this.contacts = await this.loadHorseContacts(this.horse_id)
				this.table_busy = false
			},
			addContactFromTiers() {
				this.$emit('addContactFromTiers')
			}
		},
		computed: {
			hasHubspotAccess() {
				return this.$store.state.userAccess.hasHubspotAccess
			},
			
			horseFicheReadOnly() {
				// if(this.dashboard) {
				// 	return true
				// }
                return this.$store.state.horseFicheReadonly
            },
			filtredContacts: function() {
				let contactsFormated = []
				if(!this.contacts) {
					return []
				}

				const copy_temp = this.deppCloneObj(this.contacts)
				for(let i = 0; i < copy_temp.length; i++) {
					const temp = {...copy_temp[i], ...copy_temp[i].contact}
					if(temp.contact.contact_id) {
						temp.phones_number = this.getArrayObjProperty(temp.phones, "phone_combine").join("<br>")
						temp.fonctions_label = this.getArrayObjProperty(temp.fonctions, "fonction_label")
						temp.fonctions_label = temp.fonctions_label.map((v) => {
							return this.getTrad(v)
						})

						temp.contact_poste = temp.fonctions_label.join("<br>");
						temp.pays = Countries[Vue.i18n.locale()].label[temp.contact_country]
						contactsFormated.push(temp);
					}
				}

				return contactsFormated
			},
			tableId: function() {
                // if(this.dashboard) {
				// 	return 'contacts_dashboard'
				// }
				// else {
					return 'contacts'
				// }
			},
		},
		components: {
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ContactForm : () => import('@/components/Contact/ContactForm')
		}
	}
</script>
